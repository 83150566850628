<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Project name
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">View</span>
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="project in projects" :key="project.id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ project.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<v-button
										@click="restoreProject(project.project_id)"
										:loading="restoringProjectId == project.project_id"
										class="btn btn-action-outline btn-small"
										>Restore</v-button
									>
									<v-button
										@click="deleteProject(project.project_id, project.name)"
										class="btn btn-danger-outline btn-small ml-3"
										>Delete</v-button
									>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<Modal v-show="confirmDeleteModal" :widthPercent="50" @close="confirmDeleteModal = false">
		<template v-slot:header>
			<h3>Are you sure you want to delete the project?</h3>
		</template>

		<template v-slot:body>
			<div v-if="this.deleteError" class="text-red-800">Error deleting: {{ deleteError }}</div>
			<label class="flex flex-col font-bold w-full mb-5">
				<div>
					<span class="font-light">Type the project name below to delete: </span>
					<input type="text" :value="deleteProjectName" readonly />
				</div>
				<input
					class="border form-input mt-1 p-2"
					name="name"
					placeholder="Project name"
					v-model="deleteName"
					required />
			</label>
			<div class="flex">
				<v-button @click="cancelDeleteProject" class="btn btn-default mr-3">Cancel</v-button>
				<v-button @click="confirmDeleteProject" :loading="deletingProject" class="btn btn-danger"
					>Delete project</v-button
				>
			</div>
		</template>
	</Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/ui/Button";
import Modal from "@/components/Modal";

export default {
	// mixins: [crudable],

	created() {},

	mounted() {
		this.getProjectList();
	},

	components: {
		Modal,
		"v-button": Button,
	},

	data() {
		return {
			restoringProjectId: false,
			confirmDeleteModal: false,
			deletingProject: false,
			deleteName: null,
			deleteProjectId: null,
			deleteProjectName: null,
			deleteError: null,
			projects: [],
		};
	},

	props: ["teamId", "projectType"],

	methods: {
		async restoreProject(projectId) {
			this.restoringProjectId = projectId;
			try {
				let response = await this.$api
					.resource("projectTrash", {
						team_id: this.teamId,
						project_type: this.projectType,
						project_id: projectId,
					})
					.put({ trashed: false });

				this.confirmDeleteModal = false;
				this.getProjectList();
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
			this.restoringProjectId = false;
		},

		deleteProject(projectId, projectName) {
			this.confirmDeleteModal = true;
			this.deleteProjectId = projectId;
			this.deleteProjectName = projectName;
		},
		cancelDeleteProject() {
			this.confirmDeleteModal = false;
			this.deleteProjectId = null;
		},
		async confirmDeleteProject() {
			this.deletingProject = true;

			try {
				let response = await this.$api
					.resource("teamProjects", {
						team_id: this.teamId,
						project_type: this.projectType,
					})
					.delete(this.deleteProjectId, { name: this.deleteName });
				this.deleteProjectId = null;
				this.confirmDeleteModal = false;
				this.getProjectList();
			} catch (e) {
				console.log(`Blablah: ${e}`);
				// console.log(e)
				this.deleteError = e.response.data.title;
			}
			this.deletingProject = false;
		},

		async getProjectList() {
			try {
				let response = await this.$api
					.resource("teamProjects", {
						team_id: this.teamId,
						project_type: this.projectType,
					})
					.fetchAll({ get_trashed_projects: true });
				this.projects = response.data.projects;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},
	},

	computed: {
		// ...mapGetters([
		//     'auth',
		//     'appLoading'
		// ])
	},
};
</script>
<style></style>
