<template>
	<form @submit.prevent="save" class="form-bare">
		<label class="flex flex-col font-bold w-full">
			<span>Email</span>
			<input
				class="border form-input mt-1 p-2"
				name="user_id"
				v-model="resource.user_id"
				placeholder="Email address"
				required />
		</label>
		<label class="flex flex-col font-bold w-full">
			<span>Permission</span>
			<select class="border form-input mt-1 p-2" v-model="resource.permission" required>
				<option>ADMIN</option>
				<option>MANAGE</option>
				<option>WRITE</option>
				<option>READ</option>
			</select>
		</label>
		<v-button type="submit" class="btn btn-success" :loading="crudLoading">Invite Member</v-button>
	</form>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";
import permissions from "@/components/teams/Permissions";

export default {
	mixins: [crudable],

	mounted() {
		this.setResourceEndpoint("teamMemberInvites", { team_id: this.teamId });
	},

	components: {
		"v-button": Button,
	},

	data() {
		return {
			permissions,
		};
	},

	props: ["teamId"],

	methods: {},

	watch: {
		resourceStatus(status) {
			if (status.status == "created") {
				this.resetResource();
			}
		},
	},

	computed: {
		// crudName() {
		//     if (this.resourceId) {
		//         return 'Save'
		//     }
		//     return 'Create'
		// }
	},
};
</script>
<style></style>
