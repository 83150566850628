<template>
	<div class="flex justify-between flex-row-reverse mb-3">
		<v-button @click="inviteUser" class="btn btn-add-new"
			>Invite <span class="ml-3" v-html="plus_icon"></span
		></v-button>
	</div>

	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									User ID
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Role
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">Revoke</span>
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="invite in resource.user_invites" :key="invite.user_id">
								<MemberInvite
									:teamId="teamId"
									:userId="invite.user_id"
									:permission="invite.permission"
									@crudActionStatus="handleRevokeInviteCrud" />
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<Modal v-show="modal" :widthPercent="60" @close="modal = false">
		<template v-slot:header>
			<h3>{{ modalHeader }}</h3>
		</template>

		<template v-slot:body>
			<MemberInviteForm :teamId="teamId" @crudActionStatus="handleInviteCrud" />
		</template>
	</Modal>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import MemberInviteForm from "@/components/teams/MemberInviteForm";
import MemberInvite from "@/components/teams/MemberInvite";

const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;

export default {
	mixins: [crudable],

	created() {
		this.setResourceEndpoint("teamMemberInvites", { team_id: this.teamId });
		this.fetchAll();
	},

	components: {
		MemberInviteForm,
		MemberInvite,
		Modal,
		"v-button": Button,
	},

	data() {
		return {
			modal: false,
			modalHeader: "",
			invites: [],
			plus_icon,
		};
	},

	props: ["teamId"],

	methods: {
		inviteUser() {
			this.modalHeader = "Invite user";
			this.modal = true;
		},

		handleInviteCrud(resourceId, e, payload) {
			if (e == "created") {
				this.invites.push(payload);
				this.modal = false;
			}
		},

		handleRevokeInviteCrud(resourceId, e, payload) {
			for (var i = 0; i < this.invites.length; i++) {
				if (this.invites[i].user_id == resourceId) {
					this.invites.splice(i, 1);
					break;
				}
			}
		},
	},

	watch: {
		resourceStatus(status) {
			if (status.status == "loaded") {
				this.invites = this.resource.user_invites;
			}
		},
		$route(to, from) {
			// console.log(to, from)
			this.modal = false;
		},
	},

	computed: {},
};
</script>
<style></style>
