<template>
	<td class="px-6 py-4 whitespace-nowrap">
		<div class="text-sm text-gray-900">{{ this.resourceId }}</div>
	</td>
	<td class="px-6 py-4 whitespace-nowrap">
		<div class="text-sm text-gray-900">{{ this.permission }}</div>
	</td>
	<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
		<v-button @click="revokeInvite" class="btn btn-small btn-warning-outline" :loading="crudLoading"
			>Revoke</v-button
		>
	</td>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";

export default {
	mixins: [crudable],

	created() {
		if (this.userId) {
			this.resourceId = this.userId;
			this.setResourceEndpoint("teamMemberInvites", { team_id: this.teamId });
		}
	},

	components: {
		"v-button": Button,
	},

	props: ["teamId", "userId", "permission"],

	data() {
		return {};
	},

	methods: {
		revokeInvite() {
			this.delete();
		},
	},

	watch: {
		resourceStatus(status) {
			if (status.status == "deleted") {
				this.resetResource();
			}
		},
	},

	emits: ["crudActionStatus"],

	computed: {
		// crudName() {
		//     if (this.resourceId) {
		//         return 'Delete'
		//     }
		//     return null
		// }
	},
};
</script>

<style></style>
