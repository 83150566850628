<template>
	<div>
		<Tabs>
			<Tab name="Trashed projects" :selected="true" v-if="activeTeamPermission != 'READ'">
				<div class="lg:w-10/12">
					<!-- <MemberList :teamId="teamId" /> -->
					<div class="m-3 font-bold text-lg">Company Analysis projects</div>
					<TrashedProjectsTable :teamId="teamId" projectType="company-analysis" />
					<div class="m-3 mt-8 font-bold text-lg">Prospect projects</div>
					<TrashedProjectsTable :teamId="teamId" projectType="prospects" />
				</div>
			</Tab>
			<Tab name="Members" v-if="activeTeamPermission == 'MANAGE' || activeTeamPermission == 'ADMIN'">
				<div class="lg:w-10/12">
					<MemberList :teamId="teamId" />
				</div>
			</Tab>
			<Tab name="User invites" v-if="activeTeamPermission == 'MANAGE' || activeTeamPermission == 'ADMIN'">
				<div class="lg:w-10/12">
					<MemberInvitesList :teamId="teamId" />
				</div>
			</Tab>
			<Tab name="Settings" v-if="activeTeamPermission == 'ADMIN'">
				<div class="mb-3">
					<v-button @click="deleteTeam" class="btn btn-danger">Delete workspace</v-button>
				</div>
			</Tab>
		</Tabs>

		<Modal v-show="confirmDeleteModal" :widthPercent="50" @close="confirmDeleteModal = false">
			<template v-slot:header>
				<h3>Are you sure you want to delete the workspace?</h3>
			</template>

			<template v-slot:body>
				<div v-if="this.deleteError" class="text-red-800">Error deleting: {{ deleteError }}</div>
				<label class="flex flex-col font-bold w-full mb-5">
					<div>
						<span class="font-light">Type the workspace name below to delete: </span>
						<input type="text" :value="resource.name" readonly />
					</div>
					<div class="text-yellow-600 my-3">
						<span class="text-white bg-yellow-600 font-bold px-3 py-1 rounded-full">WARNING</span>
						This action will delete all associated projects
					</div>
					<input
						class="border form-input mt-1 p-2"
						name="name"
						placeholder="Workspace name"
						v-model="deleteName"
						required />
				</label>
				<div class="flex">
					<v-button @click="cancelDeleteTeam" class="btn btn-default mr-3">Cancel</v-button>
					<v-button @click="confirmDeleteTeam" :loading="deletingTeam" class="btn btn-danger"
						>Delete workspace</v-button
					>
				</div>
			</template>
		</Modal>
	</div>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import TrashedProjectsTable from "@/components/teams/TrashedProjectsTable";
import MemberInvitesList from "@/components/teams/MemberInvitesList";
import MemberList from "@/components/teams/MemberList";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import Tab from "@/components/ui/Tab";
import Tabs from "@/components/ui/Tabs";
import { mapGetters } from "vuex";

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>`;
const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;

export default {
	mixins: [crudable],

	created() {
		if (this.teamId) {
			this.resourceId = this.teamId;
			this.setResourceEndpoint("teams");
			this.fetchOne();
		}
	},

	components: {
		Modal,
		TrashedProjectsTable,
		MemberInvitesList,
		MemberList,
		Tab,
		Tabs,
		"v-button": Button,
	},

	props: ["teamId", "deletedProjectId"],

	data() {
		return {
			back_icon,
			plus_icon,
			confirmDeleteModal: false,
			deletingTeam: false,
			deleteName: null,
			deleteError: null,
		};
	},

	methods: {
		deleteTeam() {
			this.confirmDeleteModal = true;
		},
		cancelDeleteTeam() {
			this.confirmDeleteModal = false;
		},
		confirmDeleteTeam() {
			this.deletingTeam = true;
			this.delete({ name: this.deleteName });
		},
	},

	watch: {
		resourceStatus(status) {
			if (status.status == "deleted") {
				this.$router.push({
					name: "user-workspaces",
				});
			} else if (status.status == "delete_error") {
				this.deletingTeam = false;
				this.deleteError = this.errorResource.title;
			}
		},
		$route(to, from) {
			// console.log(to, from)
			this.confirmDeleteModal = false;
		},
	},
	mounted() {},
	updated() {
		// console.log(this.$route)
		// this.confirmDeleteModal = false;
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
