<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Name
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									User ID
								</th>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Role
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">Manage</span>
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="member in members" :key="member.user_id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ member.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ member.user_id }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">
										{{ member.permission }}
									</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button
										v-if="activeTeamPermission == 'MANAGE' || activeTeamPermission == 'ADMIN'"
										@click="removeMember(member.name, member.user_id)"
										class="btn btn-danger btn-small">
										Remove
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

	<Modal v-show="confirmRemoveMemberModal" :widthPercent="50" @close="confirmRemoveMemberModal = false">
		<template v-slot:header>
			<h3>Are you sure you want to remove this member?</h3>
		</template>

		<template v-slot:body>
			<div v-if="this.deleteError" class="text-red-800">Error deleting: {{ deleteError }}</div>
			<label class="flex flex-col font-bold w-full mb-5">
				<div>
					<div class="m-3 mb-0 font-bold">{{ memberToRemoveName }}</div>
					<div class="m-3 mt-0 font-normal text-sm">{{ memberToRemoveId }}</div>
				</div>
			</label>
			<div class="flex">
				<v-button @click="cancelRemoveMember" class="btn btn-default mr-3">Cancel</v-button>
				<v-button @click="confirmRemoveMember" :loading="removingMemeber" class="btn btn-danger"
					>Remove member</v-button
				>
			</div>
		</template>
	</Modal>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import { mapGetters } from "vuex";

export default {
	mixins: [crudable],

	created() {
		// this.setResourceEndpoint('teamMembers', {team_id: this.teamId});
		// this.fetchAll()
		this.getTeamMembers();
	},

	components: {
		Modal,
		"v-button": Button,
	},

	data() {
		return {
			members: [],
			confirmRemoveMemberModal: false,
			memberToRemoveId: null,
			memberToRemoveName: null,
			removingMemeber: false,
			deleteError: null,
		};
	},

	props: ["teamId"],

	methods: {
		async getTeamMembers() {
			try {
				let response = await this.$api.resource("teamMembers", { team_id: this.teamId }).fetchAll();
				// console.dir(response);
				this.members = response.data.users;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		removeMember(name, user_id) {
			this.memberToRemoveName = name;
			this.memberToRemoveId = user_id;
			this.confirmRemoveMemberModal = true;
		},

		cancelRemoveMember() {
			this.confirmRemoveMemberModal = false;
		},

		async confirmRemoveMember() {
			this.removingMemeber = true;
			try {
				// console.log(this.$api)
				let response = await this.$api
					.resource("teamMembers", { team_id: this.teamId })
					.delete(this.memberToRemoveId);
				// console.log(response)
				if (this.memberToRemoveId == this.auth.user.email) {
					// Send user to their list of workspaces
					// - because they delete themselves
					this.$router.push({
						name: "user-workspaces",
					});
				} else {
					this.getTeamMembers();
				}
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
			this.removingMemeber = false;
			this.confirmRemoveMemberModal = false;
		},
	},

	watch: {
		$route(to, from) {
			// console.log(to, from)
			this.confirmRemoveMemberModal = false;
		},
	},

	computed: {
		...mapGetters(["auth", "activeTeamPermission"]),
	},
};
</script>
<style></style>
